import fetch from '@/utils/fetch';

// 获取卡密信息
export function queryCard (data) {
  return fetch({
    url: '/api/link/mobile/v2/card',
    method: 'GET',
    params: data,
  });
}

// 获取卡密信息
export function queryPhone (data) {
  return fetch({
    url: '/api/link/mobile/v2/info',
    method: 'GET',
    params: data,
  });
}

// 无码办理
export function link (data) {
  return fetch({
    url: '/api/link/mobile/v2/link',
    method: 'POST',
    params: data,
  });
}

// 获取第一个验证码
export function getCode (data) {
  return fetch({
    url: '/api/link/mobile/v2/code',
    method: 'GET',
    params: data,
  });
}

// 提交第一个验证码
export function postCode (data) {
  return fetch({
    url: '/api/link/mobile/v2/code',
    method: 'POST',
    params: data,
  });
}

// 获取第二个验证码
export function getSecondCode (data) {
  return fetch({
    url: '/api/link/mobile/v2/second/code',
    method: 'GET',
    params: data,
  });
}

// 提交第二个验证码
export function postSecondCode (data) {
  return fetch({
    url: '/api/link/mobile/v2/second/code',
    method: 'POST',
    params: data,
  });
}

// 无验证码办理
export function handler (data) {
  return fetch({
    url: '/api/link/mobile/v2/handler',
    method: 'POST',
    params: data,
  });
}
